










































  import { Toaster } from '@/constant/Toaster';
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    name: 'formHelp',
    components: {},
    mixins: [],
  })
  export default class formHelp extends Vue {
    public onLoad = false;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit(ev: Event) {
      this.onLoad = true;
      setTimeout(() => {
        (ev.target as HTMLFormElement).reset();
        this.onLoad = false;
        Toaster.Make('Submited', { title: 'Success', variant: 'success' });
      }, 800);
    }
  }
